// chart utils
import * as d3 from 'd3';
import { formatInteger } from 'utils/format';
import { copyToClipboard } from 'utils/copyToClipboard';
import { formatDate } from 'utils/dates';

const zoomFactor = 4;

export const zoomIn = (chart) => {
	const currentZoom = chart.zoom();
	const x1 = currentZoom[0] + zoomFactor;
	const x2 = currentZoom[1] - zoomFactor;
	if (x2 - x1 > 2) chart.zoom([x1, x2]);
};

export const zoomOut = (chart) => {
	const currentZoom = chart.zoom();
	const max = chart.data()[0].values.length;
	const x1 = Math.max(currentZoom[0] - zoomFactor, 0);
	const x2 = Math.min(currentZoom[1] + zoomFactor, max);
	chart.zoom([x1, x2]);
};

export const onFocused = (chart, id) => {
	if (id === 'consumptions') {
		chart.focus(['consumptions', 'consumptions2', 'consumptions3']);
	} else {
		chart.focus(id);
	}
};

export const onToggle = (chart, toggled, id, e) => {
	let toShow = [];
	let toHide = [];

	toggled[id] = !toggled[id];

	function doToggle(ids, value) {
		if (value) {
			toShow = toShow.concat(ids);
		} else {
			toHide = toHide.concat(ids);
		}
	}

	const isTargetShown = (targetId) => chart.data.shown().some(item => item.id === targetId);

	if (id === 'consumptions') {
		doToggle(['consumptions', 'consumptions2', 'consumptions3'], !isTargetShown('consumptions'));
	} else {
		chart.toggle(id);
	}

	if (!toggled.consumptions) {
		doToggle(['consumptions'], isTargetShown('qmLotDeliveries') || isTargetShown('fixedOrderDeliveries'));
		doToggle(['consumptions2'], isTargetShown('genLotsDeliveries') || isTargetShown('genLotsScrap'));
		doToggle(['consumptions3'], isTargetShown('comparisonDeliveries') || isTargetShown('comparisonScrap'));
	}

	chart.show(toShow);
	chart.hide(toHide);

	// Toggle class in legend
	e.currentTarget.classList.toggle('Toggled');
};

export const addGenLotDeliveryLabels = ({paddingLeft, orderDates, genLotsDeliveries, unit }) => {
	const container = d3.select("#chart");
	d3.selectAll('.genLotDeliveriesLabel').remove();
	const arcs = d3.selectAll('.bb-shapes-genLotsDeliveries path');

	arcs.each((o, i, nodes) => {
		const delivered = genLotsDeliveries[i];
		if (delivered) {
			const bBox = nodes[i].getBBox();
			container
				.append('div')
				.attr('class', 'genLotDeliveriesLabel')
				.html(
					`<span>${formatDate(orderDates[i])}</span>` +
					`<span>${formatInteger(delivered)} ${unit || 'kg'}</span>`
				)
				.style('left', `${bBox.x + (paddingLeft || 0)}px`)
				.style('top', `${bBox.y}px`)
				.on('click', () => copyToClipboard(delivered));
		}
	});
};

export const computeScale = (columns, y2Columns) => {
	// Computes scales if there are two y axes

	const getMinMax = (arr) => {
		return arr.length ? [Math.min(...arr), Math.max(...arr)] : [0, 0];
	};

	const y1Values = columns
		.filter(col => !y2Columns.includes(col[0]))
		.flatMap(col => col.slice(1));
	const [y1Min, y1Max] = getMinMax([0, ...y1Values]);

	const y2Values = columns
		.filter(col => y2Columns.includes(col[0]))
		.flatMap(col => col.slice(1));
	const [y2Min, y2Max] = getMinMax([0, ...y2Values]);

	// Calculate the scale factor
	const scaleFactor = (y1Max - y1Min) / (y2Max - y2Min) || 1; // Avoid division by zero

	// Scale y2 min to y1 range and y1 max to y2 range
	const scale1 = y2Min * scaleFactor;
	const scale2 = y1Max / scaleFactor;

	return { scale1, scale2 };
};

