// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body{color:#56646e;background:#fff !important;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;transition:all 300ms ease-in-out}input:-webkit-autofill{-webkit-box-shadow:0 0 0 1000px #fff inset;-webkit-text-fill-color:#000 !important}input::-webkit-outer-spin-button,input::-webkit-inner-spin-button{-webkit-appearance:none;margin:0}input[type=number]{-moz-appearance:textfield}a{text-decoration:none}button{background:#fff}.app-containers-App-styles-App{width:100%;height:100%;margin:auto;display:flex}.app-containers-App-styles-Main{flex-grow:1}::-webkit-scrollbar{width:1em}::-webkit-scrollbar-thumb{background-color:#e0e0e0;border-radius:4px;border:none}.userback-button{z-index:10 !important}uclose svg{fill:#fff !important}", "",{"version":3,"sources":["webpack://./app/containers/App/styles.scss","webpack://./app/style/variables.scss"],"names":[],"mappings":"AAGA,KACC,aCYa,CDXb,0BAAA,CACA,kCAAA,CACA,iCAAA,CC4CA,gCAAA,CDxCD,uBACC,0CAAA,CACA,uCAAA,CAGD,kEAEC,uBAAA,CACA,QAAA,CAID,mBACC,yBAAA,CAGD,EACC,oBAAA,CAGD,OACC,eAAA,CAGD,+BACC,UAAA,CACA,WAAA,CACA,WAAA,CACA,YAAA,CAGD,gCACC,WAAA,CAID,oBACC,SAAA,CAOD,0BACC,wBChCS,CDiCT,iBAAA,CACA,WAAA,CAID,iBACC,qBAAA,CAGD,WACC,oBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"App": "app-containers-App-styles-App",
	"Main": "app-containers-App-styles-Main"
};
export default ___CSS_LOADER_EXPORT___;
