import React, { useEffect, useState } from 'react';
import { redirect, useLoaderData, useLocation } from 'react-router-dom';
import { Button, Collapse, Dialog, DialogContent, FormControlLabel, Grid, Switch, Tooltip } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp, Eco, ExpandLess, ExpandMore, GetApp, Share, Visibility } from '@material-ui/icons';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import StatusFilter from 'components/button/StatusFilter';
import { Transition } from 'components/Dialog';
import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import Spacer from 'components/Spacer';
import InlineEdit from 'pages/SimulatorEditionPage/InlineEdit';
import { ResultsChart } from 'containers/results/ResultsChart';
import ParametersDiff from './ParametersDiff';
import Table from './Table';
import WaterfallChart from './WaterfallChart';
import { getComparison, updateComparison } from 'modules/api2/comparison';
import { compareScenarios, extractAllScenariosFromComparison, isEditedMaterial, sumSavings } from 'modules/scenario';
import { useStoreContext } from 'Context';
import config from 'config.js';
import { copyToClipboard } from 'utils/copyToClipboard';
import { mergeClasses } from 'utils/classHelper';
import { daysToHorizon, formatDate, frequencyToUnit } from 'utils/dates';
import { formatRoundedCurrency, formatShortNumber, percentDiff } from 'utils/format';
import { download } from 'utils/download';
import { preProcessComparison } from './filters';
import styles from './styles.scss';


const GET_COMPARISON_PARAMS = { withMaterials: true };

export async function loader({ params }) {
	const comparison = await getComparison(params.comparisonId, GET_COMPARISON_PARAMS);
	if (comparison.status !== 'FINISHED') return redirect(`/simulator/${params.comparisonId}`);
	const filteredComparison = preProcessComparison(comparison, true);
	return { comparison: filteredComparison };
}

export function SimulatorResultsPage() {

	const [baseScenarioId, setBaseScenarioId] = useState('genLots');
	const [collapsed, setCollapsed] = useState(false);
	const [currentMaterial, setCurrentMaterial] = useState(null);
	const [currentScenarioId, setCurrentScenarioId] = useState('');
	const [sorted, setSorted] = useState([{ id: 'number', desc: false }]);
	const [toggled, setToggled] = useState(null);
	const [filterEdited, setFilterEdited] = useState(true);
	const location = useLocation();
	const { features } = useStoreContext();


	const { comparison: initialComparison } = useLoaderData();
	const [comparison, setComparison] = useState(initialComparison);


	const refreshComparison = async () => {
		const comparison = await getComparison(initialComparison.id, GET_COMPARISON_PARAMS);
		const filteredComparison = preProcessComparison(comparison, filterEdited);
		setComparison(filteredComparison);
	};

	useEffect(() => {
		const fetchData = async () => {
			await refreshComparison();
		};
		fetchData();
	}, [filterEdited]);


	document.title = `${comparison.description} - Simulation edition`;

	const allScenarios = extractAllScenariosFromComparison(comparison);

	if (Object.keys(comparison).length === 0 || Object.keys(allScenarios).length === 0) return <LoadingIndicator />;

	if (currentScenarioId === '' || !comparison.scenarios.find(o => o.id === currentScenarioId)) {
		setCurrentScenarioId(comparison.scenarios[0].id);
		return <LoadingIndicator />;
	}

	const downloadResults = comparison => {
		download(`${config.apiUrl}/comparison/${comparison.id}/report`, `${comparison.description}.xlsx`);
	};

	const currency = comparison.materials[0] ? comparison.materials[0].currency : '';

	const baseScenario = allScenarios[baseScenarioId];
	const currentScenario = allScenarios[currentScenarioId];
	const sumBase = sumSavings(baseScenario);

	const currentScenarioVsBase = compareScenarios(currentScenario, baseScenario);
	const sumScenarioVsBase = sumSavings(currentScenarioVsBase);

	// Needed to display the previous parameter value
	currentScenarioVsBase.forEach(m => {
		m.base = baseScenario.find(i => i.number === m.number); // eslint-disable-line no-param-reassign
		m.edited = isEditedMaterial(m); // eslint-disable-line no-param-reassign
	});

	const allMaterials = comparison.unfilteredMaterials || comparison.materials;

	return (
		<div>
			<PageHeader
				backTo="/simulator"
				right={
					<>
						<Button size="large" variant="contained" color="primary" startIcon={<GetApp />} onClick={() => downloadResults(comparison)}>
							Download
						</Button>
						&nbsp;&nbsp;&nbsp;
						<Button
							size="large"
							variant="outlined"
							startIcon={<Share />}
							onClick={e => {
								e.stopPropagation();
								copyToClipboard(window.location.origin + location.pathname);
							}}>
							Share
						</Button>
					</>
				}
				secondLine={
					<Grid container justifyContent="space-between">
						<Grid item>
							<Grid container className={styles.Summary}>
								<Grid item>
									<span>Created</span>
									<span>{formatDate(comparison.creationDate)}</span>
									<span>by</span>
									<span>Admin</span>
								</Grid>
								<Grid item>
									<span>Start date: </span>
									<span>{allMaterials[0] && formatDate(allMaterials[0].importDate)}</span>
								</Grid>
								<Grid item>
									<span>Horizon: </span>
									<span>
										{daysToHorizon(comparison.horizon, comparison.frequency)} {frequencyToUnit(comparison.frequency)}
									</span>
								</Grid>
								<Grid item>
									<span>Includes</span>
									<span className={styles.Orange}>{allMaterials.length} materials</span>
									{comparison.failedMaterials > 0 && (
										<Tooltip title={`The computation of the optimal order plan failed for ${comparison.failedMaterials} materials`}>
											<span>
												out of <span className={styles.Orange}>{allMaterials.length + comparison.failedMaterials}</span>
											</span>
										</Tooltip>
									)}
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							<Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
								{features.fullDashboard && (
									<Grid item>
										<FormControlLabel
											label="All materials"
											control={
												<Switch
													checked={!!window.cdashboard}
													onChange={async (event, value) => {
														window.cdashboard = value ? features.fullDashboard : null;
														await refreshComparison();
													}}
												/>
											}
										/>
									</Grid>
								)}
								<Grid item>
									<StatusFilter startIcon={<Visibility style={{ verticalAlign: 'middle' }} />}
																onChange={() => setFilterEdited(!filterEdited)} selected={filterEdited}>
										Edited materials ({comparison.editedMaterials.length})
									</StatusFilter>
								</Grid>
								<Grid item>
									<span>Compare to:&nbsp;&nbsp;</span>
									<ToggleButtonGroup value={baseScenarioId} onChange={(e, value) => value && setBaseScenarioId(value)} exclusive>
										<ToggleButton value="genLots">GenLots</ToggleButton>
										<ToggleButton value="SAP">Current Plan</ToggleButton>
									</ToggleButtonGroup>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				}>
				<InlineEdit value={comparison.description}
										onBlur={description => {
											updateComparison(comparison.id, { description });
										}}
				/>
			</PageHeader>
			<div className={styles.Body}>
				<Grid container className={styles.Overview} justifyContent="center">
					<Grid item xs={2} className={styles.Label}>
						<div className={styles.ScenarioTitle} />
						<div>Average Inventory Value</div>
						<div>Number of orders</div>
						<div className={styles.SectionTitle}>Costs</div>
						<div>Supply Chain cost</div>
						<div>Order Cost</div>
						<div>Inventory cost</div>
						<div>Scrap cost</div>
						<div>Purchasing cost</div>
					</Grid>
					<Grid item xs={2}>
						<div className={styles.ScenarioTitle}>{baseScenarioId === 'genLots' ? 'GenLots' : 'Current Plan'}</div>
						<CostColumns currency={currency} sum={sumBase} />
					</Grid>
					{comparison.scenarios.map(scenario => (
						<Grid key={scenario.id} item xs={2} className={styles.Scenario}>
							<div className={styles.ScenarioTitle}>Simulation</div>
							<CostColumns sum={sumScenarioVsBase} base={sumBase} currency={currency} />
						</Grid>
					))}
				</Grid>
				<Collapse in={collapsed}>
					<Grid container className={styles.Overview} justifyContent="center">
						<Grid item xs={2} className={styles.Label}>
							<div className={styles.SectionTitle}>Savings</div>
							<div>Total savings</div>
							<div>Supply Chain Savings</div>
							<div>Inventory savings</div>
							<div>Order savings</div>
							<div>Quantity discounts</div>
							<div>Scrap savings</div>
							<div className={styles.SectionTitle}>
								<span>
									CO2 <Eco style={{ marginTop: '-2px', color: 'lightgreen' }} />
								</span>
							</div>
							<div>Total</div>
							<div>Transport</div>
							<div>Warehouse</div>
						</Grid>
						<Grid item xs={2}>
							<OverviewColumns currency={currency} showDashes sum={sumBase} />
						</Grid>
						{comparison.scenarios.map(scenario => (
							<Grid key={scenario.id} item xs={2} className={styles.Scenario}>
								<OverviewColumns currency={currency} sum={sumScenarioVsBase} base={sumBase} />
							</Grid>
						))}
					</Grid>
				</Collapse>
				<Spacer size={2} />
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<Button
						onClick={() => setCollapsed(!collapsed)}
						startIcon={collapsed ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}>
						{collapsed ? 'Minimize' : 'Expand'}
					</Button>
				</div>
				<Spacer size={2} />
				<Table
					data={currentScenarioVsBase}
					onClick={r => setCurrentMaterial(r.original)}
					onSortedChange={s => setSorted(s)}
					sorted={sorted}
					setSorted={setSorted}
					setToggled={setToggled}
					toggled={toggled || comparison.editedColumns}
					resetColumns={() => setToggled(comparison.editedColumns)}
					comparison={comparison}
				/>
			</div>
			<Dialog
				onClose={() => setCurrentMaterial(null)}
				aria-labelledby="detail-title"
				maxWidth="xl"
				open={!!currentMaterial}
				TransitionComponent={Transition}>
				<DialogContent>
					{currentMaterial && (
						<div>
							<div className={styles.DialogTitle}>
								<span>{currentMaterial.name}</span>
								<span>{currentMaterial.number}</span>
							</div>
							<ParametersDiff material={currentMaterial} />
							<div className={styles.Subtitle}>Order Plan</div>
							<ResultsChart material={currentMaterial} className={styles.ResultsChart} type={'simulation'} />
							<div className={styles.Subtitle}>Cost Comparison</div>
							<div>
								<WaterfallChart material={currentMaterial} />
							</div>
						</div>
					)}
				</DialogContent>
			</Dialog>
		</div>
	);
}


function Currency({ currency }) {
	return <span className={styles.Unit}>{currency}</span>;
}

function OverviewColumns({ sum, currency, base, showDashes = false }) {
	const Cell = ({ children }) => (
		<div>
			<div />
			<div>{children}</div>
		</div>
	);
	const SavingsCell = ({ value }) => (
		<Cell>
			{showDashes ? (
				'-'
			) : (
				<>
					{formatRoundedCurrency(value)}
					<Currency currency={currency} />
				</>
			)}
		</Cell>
	);
	const Co2Cell = ({ value, original }) => (
		<div>
			<Badge a={value} b={original} />
			<div>
				{formatShortNumber(value)}
				<Currency currency="tCO2e" />
			</div>
		</div>
	);
	return (
		<>
			<div className={styles.SectionTitle} />
			<SavingsCell value={sum.pureSavings} />
			<SavingsCell value={sum.supplyChainSavings} />
			<SavingsCell value={sum.inventorySavings} />
			<SavingsCell value={sum.orderSavings} />
			<SavingsCell value={sum.quantityDiscounts} />
			<SavingsCell value={sum.scrapSavings} />
			<div className={styles.SectionTitle} />
			<Co2Cell value={sum.CO2} original={base && base.CO2} />
			<Co2Cell value={sum.CO2Transport} original={base && base.CO2Transport} />
			<Co2Cell value={sum.CO2Warehouse} original={base && base.CO2Warehouse} />
		</>
	);
}

function CostColumns({ sum, base = {}, currency }) {
	return (
		<>
			<div>
				<Badge a={sum.averagePositiveInventoryValue} b={base.averagePositiveInventoryValue} />
				<div>
					{formatRoundedCurrency(sum.averagePositiveInventoryValue)}
					<Currency currency={currency} />
				</div>
			</div>
			<div>
				<Badge a={sum.ordersCount} b={base.ordersCount} />
				<div>{sum.ordersCount}</div>
			</div>
			<div className={styles.SectionTitle} />
			<div>
				<Badge a={sum.supplyChainCost} b={base.supplyChainCost} />
				<div>
					{formatRoundedCurrency(sum.supplyChainCost)}
					<Currency currency={currency} />
				</div>
			</div>
			<div>
				<Badge a={sum.orderCost} b={base.orderCost} />
				<div>
					{formatRoundedCurrency(sum.orderCost)}
					<Currency currency={currency} />
				</div>
			</div>
			<div>
				<Badge a={sum.carryingCost} b={base.carryingCost} />
				<div>
					{formatRoundedCurrency(sum.carryingCost)}
					<Currency currency={currency} />
				</div>
			</div>
			<div>
				<Badge a={sum.scrapCost} b={base.scrapCost} />
				<div>
					{formatRoundedCurrency(sum.scrapCost)}
					<Currency currency={currency} />
				</div>
			</div>
			<div>
				<Badge a={sum.rawPurchasingCost} b={base.rawPurchasingCost} />
				<div>
					{/* dash if not available, remove when confident it is provided */}
					{formatRoundedCurrency(sum.rawPurchasingCost) || '—'}
					<Currency currency={currency} />
				</div>
			</div>
		</>
	);
}

const Badge = ({ a, b }) => {
	const value = Math.round(percentDiff(a, b) * 10) / 10;
	return (!Number.isFinite(b) || value === 0) ? (
		<div />
	) : (
		<div className={mergeClasses(styles.Badge, triCond(value, styles.Green, styles.Red))}>
			{triCond(value, <ArrowDropDown />, <ArrowDropUp />)}
			{formatShortNumber(Math.abs(value), 1)} %
		</div>
	);
};

// Create some ad hoc components
const triCond = (v, below, above, equal = null) => (v > 0 ? above : v < 0 ? below : equal); // eslint-disable-line no-nested-ternary, no-confusing-arrow
export default SimulatorResultsPage;
