// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-pages-SimulatorCalculatingPage-styles-Loading{text-align:center}", "",{"version":3,"sources":["webpack://./app/pages/SimulatorCalculatingPage/styles.scss"],"names":[],"mappings":"AACA,mDACC,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Loading": "app-pages-SimulatorCalculatingPage-styles-Loading"
};
export default ___CSS_LOADER_EXPORT___;
