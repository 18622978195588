// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-pages-SimulatorEditionPage-InlineEdit-styles-Title>div>div{opacity:0}.app-pages-SimulatorEditionPage-InlineEdit-styles-Title>div:hover>div{opacity:1}.app-pages-SimulatorEditionPage-InlineEdit-styles-Title>div:before{border-color:rgba(0,0,0,0) !important}.app-pages-SimulatorEditionPage-InlineEdit-styles-Title input{color:#7b87aa;font-size:26px}", "",{"version":3,"sources":["webpack://./app/pages/SimulatorEditionPage/InlineEdit/styles.scss"],"names":[],"mappings":"AAGE,gEACC,SAAA,CAGD,sEACC,SAAA,CAGD,mEACC,qCAAA,CAIF,8DACC,aAAA,CACA,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Title": "app-pages-SimulatorEditionPage-InlineEdit-styles-Title"
};
export default ___CSS_LOADER_EXPORT___;
