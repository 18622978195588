// This file is used to store the navigate function from the React Router for use in sagas

// navigateFn.js
let navigateFn;

// This function will store the navigate function for later use
export const setNavigate = (navigate) => {
	navigateFn = navigate;
};

// This function will allow navigation from outside React components
export const navigate = (path) => {
	if (navigateFn) {
		navigateFn(path);  // Calls the stored navigate function
	} else {
		console.error('Navigate function is not set');
	}
};
