// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-pages-SimulatorEditionPage-Table-styles-CurrentPlanHeader{background:#fafbff !important;color:rgba(123,135,170,.7) !important}.app-pages-SimulatorEditionPage-Table-styles-CurrentPlan{background:#fafbff;color:#7b87aa}.app-pages-SimulatorEditionPage-Table-styles-Modified{width:100%;text-align:right;display:flex;justify-content:space-between}.app-pages-SimulatorEditionPage-Table-styles-Modified>:nth-child(1){font-size:10px}.app-pages-SimulatorEditionPage-Table-styles-Modified>:nth-child(2){color:#5b63b6;font-weight:bold}.app-pages-SimulatorEditionPage-Table-styles-Modified>:nth-child(2)>div{text-align:right}.app-pages-SimulatorEditionPage-Table-styles-Modified .app-pages-SimulatorEditionPage-Table-styles-Calculated{font-style:italic}.app-pages-SimulatorEditionPage-Table-styles-Modified .app-pages-SimulatorEditionPage-Table-styles-Calculated img{width:23px;object-fit:contain;margin:-6px -4px}.app-pages-SimulatorEditionPage-Table-styles-ModifiedName{color:#5b63b6;font-weight:bold}.app-pages-SimulatorEditionPage-Table-styles-SelectReactTable .app-pages-SimulatorEditionPage-Table-styles-MetaHeader:first-child{max-width:60px;width:60px}.app-pages-SimulatorEditionPage-Table-styles-SelectReactTable .rt-td:nth-child(1),.app-pages-SimulatorEditionPage-Table-styles-SelectReactTable .rt-td:nth-child(2){margin-top:2px}", "",{"version":3,"sources":["webpack://./app/pages/SimulatorEditionPage/Table/styles.scss","webpack://./app/style/variables.scss"],"names":[],"mappings":"AAIA,+DACC,6BAAA,CACA,qCAAA,CAGD,yDACC,kBAAA,CACA,aAAA,CAID,sDACC,UAAA,CACA,gBAAA,CACA,YAAA,CACA,6BAAA,CAEA,oEACC,cAAA,CAGD,oEACC,aCdW,CDeX,gBAAA,CAEA,wEACC,gBAAA,CAIF,8GACC,iBAAA,CAEA,kHACC,UAAA,CACA,kBAAA,CACA,gBAAA,CAMH,0DACC,aCnCY,CDoCZ,gBAAA,CAKA,kIACC,cAAA,CACA,UAAA,CAGD,oKACC,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CurrentPlanHeader": "app-pages-SimulatorEditionPage-Table-styles-CurrentPlanHeader",
	"CurrentPlan": "app-pages-SimulatorEditionPage-Table-styles-CurrentPlan",
	"Modified": "app-pages-SimulatorEditionPage-Table-styles-Modified",
	"Calculated": "app-pages-SimulatorEditionPage-Table-styles-Calculated",
	"ModifiedName": "app-pages-SimulatorEditionPage-Table-styles-ModifiedName",
	"SelectReactTable": "app-pages-SimulatorEditionPage-Table-styles-SelectReactTable",
	"MetaHeader": "app-pages-SimulatorEditionPage-Table-styles-MetaHeader"
};
export default ___CSS_LOADER_EXPORT___;
