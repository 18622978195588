// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-components-PageHeader-styles-PageHeader{position:fixed;top:0;width:calc(100vw - 50px - 15px);background:#fafbff;z-index:1;padding:12px}@media only screen and (min-width: 1400px){.app-components-PageHeader-styles-PageHeader{padding:16px}}@media only screen and (min-width: 1400px){.app-components-PageHeader-styles-PageHeader{width:calc(100vw - 200px - 15px)}}.app-components-PageHeader-styles-PageHeader h1{color:#7b87aa;font-weight:400;font-size:26px;margin:0}.app-components-PageHeader-styles-PageHeader>:nth-child(2){padding:10px 0 0}.app-components-PageHeader-styles-Title{color:#7b87aa;font-size:26px;margin:0 10px;font-weight:400;flex-grow:1;display:flex;align-items:center}", "",{"version":3,"sources":["webpack://./app/components/PageHeader/styles.scss","webpack://./app/style/variables.scss"],"names":[],"mappings":"AACA,6CACC,cAAA,CACA,KAAA,CACA,+BAAA,CACA,kBAAA,CACA,SAAA,CC4EA,YAAA,CAlBA,2CD/DD,6CCmFE,YAAA,CAAA,CApBD,2CD/DD,6CASE,gCAAA,CAAA,CAGD,gDACC,aAAA,CACA,eAAA,CACA,cAAA,CACA,QAAA,CAGD,2DACC,gBAAA,CAIF,wCACC,aAAA,CACA,cAAA,CACA,aAAA,CACA,eAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PageHeader": "app-components-PageHeader-styles-PageHeader",
	"Title": "app-components-PageHeader-styles-Title"
};
export default ___CSS_LOADER_EXPORT___;
