// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-pages-SimulatorEditionPage-styles-Body{padding-top:135px !important;padding:12px}@media only screen and (min-width: 1400px){.app-pages-SimulatorEditionPage-styles-Body{padding:16px}}.app-pages-SimulatorEditionPage-styles-Count{color:#ffa900;font-size:30px;padding:0 10px}.app-pages-SimulatorEditionPage-styles-Timer{color:#5b63b6;font-size:30px;padding:0 10px 0 20px}.app-pages-SimulatorEditionPage-styles-Button{padding:0 0 0 20px}.app-pages-SimulatorEditionPage-styles-Filter{border-color:#5b63b6 !important;border-radius:18px !important;border-width:2px !important;color:#5b63b6 !important;font-weight:bold !important}.app-pages-SimulatorEditionPage-styles-Select{color:#56646e}.app-pages-SimulatorEditionPage-styles-Date{margin:0 !important;width:160px}.app-pages-SimulatorEditionPage-styles-Date input{padding-top:10.5px;padding-bottom:10.5px;color:#56646e}.app-pages-SimulatorEditionPage-styles-Date>div{padding-right:0}.app-pages-SimulatorEditionPage-styles-Date button{padding:5px 14px 5px 5px !important}.app-pages-SimulatorEditionPage-styles-NoEdit{opacity:50%}", "",{"version":3,"sources":["webpack://./app/pages/SimulatorEditionPage/styles.scss","webpack://./app/style/variables.scss"],"names":[],"mappings":"AACA,4CACC,4BAAA,CCgFA,YAAA,CAlBA,2CD/DD,4CCmFE,YAAA,CAAA,CD9EF,6CACC,aAAA,CACA,cAAA,CACA,cAAA,CAGD,6CACC,aAAA,CACA,cAAA,CACA,qBAAA,CAGD,8CACC,kBAAA,CAGD,8CACC,+BAAA,CACA,6BAAA,CACA,2BAAA,CACA,wBAAA,CACA,2BAAA,CAGD,8CACC,aCfa,CDkBd,4CACC,mBAAA,CACA,WAAA,CAEA,kDACC,kBAAA,CACA,qBAAA,CACA,aCzBY,CD4Bb,gDACC,eAAA,CAGD,mDACC,mCAAA,CAIF,8CACC,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Body": "app-pages-SimulatorEditionPage-styles-Body",
	"Count": "app-pages-SimulatorEditionPage-styles-Count",
	"Timer": "app-pages-SimulatorEditionPage-styles-Timer",
	"Button": "app-pages-SimulatorEditionPage-styles-Button",
	"Filter": "app-pages-SimulatorEditionPage-styles-Filter",
	"Select": "app-pages-SimulatorEditionPage-styles-Select",
	"Date": "app-pages-SimulatorEditionPage-styles-Date",
	"NoEdit": "app-pages-SimulatorEditionPage-styles-NoEdit"
};
export default ___CSS_LOADER_EXPORT___;
