import _ from 'lodash';
import { uppercaseFirst } from 'utils/string';
import { dateToLabel, daysToHorizon, formatDate, labelToDate } from 'utils/dates';
import dayjs from 'dayjs';
import { getConfig } from './config';
import { computeScale } from './utils';

export const preprocessInvViz = ({ data, toggledPallets }) => {
	if (!data) return {};
	// Define which data is on which y-axis
	const y1Columns = ['capacity', 'comparisonInventory', 'comparisonTotalInventory', 'genLotsInventory', 'genLotsTotalInventory'];
	const y2Columns = [
		'fixedOrderDeliveries', 'qmLotDeliveries', 'consumptions', 'consumptions2', 'consumptions3',
		'comparisonDeliveries', 'comparisonScrap',
		'genLotsDeliveries', 'genLotsScrap'
	];

	const filterColumns = toggledPallets ? [] : ['capacity'];
	const allColumns = [...y1Columns, ...y2Columns].filter(item => !filterColumns.includes(item));

	const suffix = toggledPallets ? 'Pallets' : 'Value';

	// I don't know why but comparisonInventoryPallets is as comparisonPallets in data
	data['comparisonInventoryPallets'] = data['comparisonPallets'].map(v => v);
	data['genLotsInventoryPallets'] = data['genLotsPallets'].map(v => v);

	const columns = allColumns.map(key => {
		if (key === 'capacity') {
			if (toggledPallets) return [key, ...(data[key] ? data[key].map(v => v) : [])];
			return [key];
		}
		// return values with either Pallets or Capacity at the end
		return [key, ...(data[`${key}${suffix}`] ? data[`${key}${suffix}`].map(v => v) : [])];
	});


	const { scale1, scale2 } = computeScale(columns, y2Columns);

	columns.push(['faker', scale1], ['faker2', scale2]);

	const axes = [...y2Columns, 'faker2'].reduce((acc, key) => {
		acc[key] = 'y2';
		return acc;
	}, {});


	return {
		data,
		columns,
		axes
	};
};


export const preprocessChartData = ({ material, type, shiftedInventory }) => {
	const chartData = getConfig({ type });
	const baseKeys = chartData.map(item => item.id);
	const addKeys = ['consumptions2', 'consumptions3'];
	const excludeKeys = [];

	let data = { ...material.calculation };
	if (material.maxInventory) {
		data.maxInventory = _.times(data.safetyStock.length, _.constant(material.maxInventory));
	} else {
		excludeKeys.push('maxInventory');
	}
	if (type === 'SSO') {
		// Adapt data to safety stock optimizer page
		data.comparisonServiceLevel = material.serviceLevel;
		data.genLotsSafetyStock = data.safetyStock;
	}

	if (type === 'simulation') {
		// Add max inventory to simulation
		if (material.maxInventory) {
			data.genLotsMaxInventory = _.times(data.safetyStock.length, _.constant(material.maxInventory));
		}
		if (material.base.maxInventory) {
			data.comparisonMaxInventory = _.times(data.safetyStock.length, _.constant(material.base.maxInventory));
		} else if (material.maxInventory) {
			data.comparisonMaxInventory = _.times(data.safetyStock.length, _.constant(0));
		}


		// if it is a simulation, we have to first rename the data keys
		// as the naming is different in a comparison
		const toRename = ['Deliveries', 'Scrap', 'AvailableInventory', 'DeliveredInventory', 'ShiftedInventory', 'safetyStock'];

		data = _.mapKeys(data, (v, k) => {
			if (toRename.includes(k)) return `genLots${uppercaseFirst(k)}`;
			return k;
		});

		const comparison = _.mapKeys(material.base.calculation, (v, k) => `comparison${uppercaseFirst(k)}`);
		data = { ...data, ...comparison };
	}
	// Labels and dates
	data.dates = data.labels.map(l => labelToDate(l, material.frequency));
	data.orderDatesDayjs = data.dates.map(l => l.add(-material.supplierLeadTime || 0, 'days'));
	data.releaseDates = data.dates.map(l => l.add(material.qualityControlLeadTime, 'days'));
	data.labels2 = data.dates.map((d) => formatDate(d));

	// Invert some columns
	data.genLotsScrap = data.genLotsScrap.map(v => -v);
	data.comparisonScrap = data.comparisonScrap.map(v => -v);
	data.consumptions = data.consumptions.map(v => -v);
	if (data.shiftedConsumptions && shiftedInventory) {
		data.consumptions = data.shiftedConsumptions.map(v => -v);
	}

	data.consumptions2 = data.consumptions;
	data.consumptions3 = data.consumptions;

	const horizon = daysToHorizon(material.horizon, material.frequency);
	const supplierLeadTime = daysToHorizon(material.supplierLeadTime, material.frequency);
	const keys = [
		...baseKeys.filter(key => !excludeKeys.includes(key)),
		...addKeys
	];
	const columns = keys.map(key => [key, ...(data[key] ? data[key].slice(0, horizon) : [])]);

	const regions = [];

	const labelConfig = { rotated: true, x: 5, y: 10 };


	if (supplierLeadTime) regions.push({
		axis: 'x',
		start: -0.5,
		end: Math.ceil(supplierLeadTime) - 0.5,
		label: {
			text: 'Planned delivery time',
			...labelConfig
		},
		class: 'supplier-lead-time-line'
	});

	// Find end of year indices
	if (material.endOfYear) {
		const indexes = _.keys(_.pickBy(data.labels.slice(1), l => l.includes('W 01') || l.includes('M 01') || l.includes('D 01-01')));
		indexes.forEach(r => {
			regions.push({
				axis: 'x',
				start: +r - 0.5,
				end: +r + 0.5,
				class: 'end-of-year',
				label: {
					text: 'End of year',
					...labelConfig
				}
			});
		});
	}
	if (material.phaseout) {
		const phaseoutLabel = dateToLabel(material.phaseout, material.frequency);
		let phaseoutIndex = data.labels.indexOf(phaseoutLabel);
		const phaseoutDate = dayjs(material.phaseout);
		// If phaseout is before period, display phaseout from the first period
		if (phaseoutIndex < 0 && phaseoutDate.isBefore(labelToDate(data.labels[0], material.frequency))) {
			phaseoutIndex = 0;
		}
		if (phaseoutIndex > -1) {
			regions.push({
				axis: 'x',
				start: phaseoutIndex - 0.5,
				end: data.labels.length,
				label: {
					text: 'Phaseout',
					...labelConfig
				},
				class: 'phaseout-region'
			});
		}
	}

	return {
		data,
		columns,
		regions
	};
};
