// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-components-Tooltip-styles-QuestionMarkIcon{vertical-align:middle;width:15px;height:19px;display:inline-block;line-height:0;font-size:0}.app-components-Tooltip-styles-QuestionMarkIcon .app-components-Tooltip-styles-Svg{display:inline-block}.app-components-Tooltip-styles-QuestionMarkIcon .app-components-Tooltip-styles-Svg:nth-child(even){clear:right}", "",{"version":3,"sources":["webpack://./app/components/Tooltip/styles.scss"],"names":[],"mappings":"AACA,gDACC,qBAAA,CACA,UAAA,CACA,WAAA,CACA,oBAAA,CACA,aAAA,CACA,WAAA,CAEA,mFACC,oBAAA,CAEA,mGACC,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"QuestionMarkIcon": "app-components-Tooltip-styles-QuestionMarkIcon",
	"Svg": "app-components-Tooltip-styles-Svg"
};
export default ___CSS_LOADER_EXPORT___;
