import { formatNumber, formatShortQuantity } from 'utils/format';
import { dateToWeekShort, formatDate } from 'utils/dates';
import { getLabel } from './config';
import { getIconHtml } from './CustomIcon';

const createRow = ({ id, unit, label, value }) => {
	if (id && value) {
		// Format quantity with unit
		return `
    <div class="Row">
      <div>${getIconHtml(id, { width: '17px', height: '17px' })}</div>
      <div>${getLabel(id, true, true)}</div>
      <div>${formatShortQuantity(value)} ${unit}</div>
    </div>
    `;
	}
	if (label && value) {
		return `
    <div class="Row">
      <div></div>
      <div>${label}</div>
      <div>${value}</div>
    </div>
    `;
	}
	return '';
};

const createOrderSection = (d, data, unit, group) => {
	const rows = [];
	const groupPrefix = group === 'GenLots' ? 'genLots' : 'comparison';
	const deliveries = d.find(item => item.id === `${groupPrefix}Deliveries`);
	const index = deliveries.index;
	const scrap = d.find(item => item.id === `${groupPrefix}Scrap`);
	const availableInventory = d.find(item => item.id === `${groupPrefix}AvailableInventory`);
	const deliveredInventory = d.find(item => item.id === `${groupPrefix}DeliveredInventory`);

	if (deliveries?.value) {
		rows.push(createRow({ ...deliveries, unit }));
		rows.push(createRow({
			label: 'Opening',
			value: formatDate(data.orderDatesDayjs[index])
		}));
		rows.push(createRow({
			label: 'Delivery',
			value: formatDate(data.dates[index])
		}));
		rows.push(createRow({
			label: 'Release',
			value: formatDate(data.releaseDates[index])
		}));
	}
	rows.push(createRow({ ...scrap, unit }));
	rows.push(createRow({ ...availableInventory, unit }));
	rows.push(createRow({ ...deliveredInventory, unit }));

	return rows.length ? `
    <div class="Order ${group === 'GenLots' ? 'GenLots' : ''}">
      <div class="Title">${group === 'GenLots' ? 'GenLots Optimized' : 'Current Plan'}</div>
      ${rows.join('')}
    </div>
  ` : '';
};

const createOtherSection = (d, unit) => {
	const fixedOrderDeliveries = d.find(item => item.id === 'fixedOrderDeliveries');
	const qmLotDeliveries = d.find(item => item.id === 'qmLotDeliveries');
	const consumptions = d.find(item => item.id === 'consumptions');
	const maxInventory = d.find(item => item.id === 'maxInventory');
	const safetyStock = d.find(item => item.id === 'safetyStock');

	const rows = [];
	rows.push(createRow({ ...fixedOrderDeliveries, unit }));
	rows.push(createRow({ ...qmLotDeliveries, unit }));
	rows.push(createRow({ ...consumptions, unit }));
	rows.push(createRow({ ...maxInventory, unit }));
	rows.push(createRow({ ...safetyStock, unit }));

	return rows.length ? `
    <div class="Order Other">
      <div class="Title">Other</div>
      ${rows.join('')}
    </div>
  ` : '';

};

export const createToolTip = (data, unit) => ({
	format: { value: val => (val ? formatNumber(val) : undefined) }, contents(d) {
		const index = d[0].index;
		const date = data.dates[index];

		return `
    <div class="GraphTooltip">
      <div class="Order Other">
        <div class="Title">${formatDate(date)} <i>(${dateToWeekShort(date)})</i></div>
      </div>
      ${createOrderSection(d, data, unit, 'GenLots')}
      ${createOrderSection(d, data, unit, 'Comparison')}
      ${createOtherSection(d, unit)}
    </div>
  `;
	}
});
