import { preprocessChartData, preprocessInvViz } from './dataPreprocessing';
import { addGenLotDeliveryLabels } from './utils';
import { graphConfig } from './config';
import bb from 'billboard.js/dist/billboard.js';
import { createToolTip } from './tooltip';

export const createChart = ({ expandLegend, material, type, shiftedInventory }) => {
	const { data, columns, regions } = preprocessChartData({ material, type, shiftedInventory });
	const headerSize = expandLegend ? 290 : 180;
	const materialPageHeight = Math.max(400, Math.min(document.documentElement.clientHeight - headerSize, 900));
	const chartHeight = (type === 'simulation') ? 550 : materialPageHeight;
	const frequency = material?.frequency;
	const unit = material?.unit;
	const chart = bb.generate({
		...graphConfig,
		axis: {
			x: {
				type: 'category', categories: data.labels2,
				tick: { rotate: -45, multiline: false, centered: true, culling: true }
			},
			y: { label: { text: unit, position: 'outer-middle' } }
		},
		size: { height: chartHeight },
		padding: frequency !== 'DAILY' ? { left: 80, top: 10 } : { top: 10 },
		data: { ...graphConfig.data, columns },
		tooltip: createToolTip(data, unit),
		regions,
		onrendered: function() {
			this.internal.$el.axis.x.selectAll('.tick line').attr('y2', 1);

			addGenLotDeliveryLabels({
				paddingLeft: this.internal.config.padding_left,
				orderDates: data.orderDatesDayjs,
				genLotsDeliveries: data.genLotsDeliveries,
				unit: unit
			});

		}
	});

	if (shiftedInventory) {
		chart.hide(['genLotsDeliveredInventory', 'comparisonDeliveredInventory']);
	} else {
		chart.hide(['genLotsShiftedInventory', 'comparisonShiftedInventory', 'genLotsDeliveredInventory', 'comparisonDeliveredInventory']);
	}

	return chart;
};

export const createInvVizChart = ({ data: inputData, toggledPallets }) => {

	const { columns, axes, data } = preprocessInvViz({ data: inputData, toggledPallets });
	const unit = toggledPallets ? 'Pallets' : 'EUR';

	return bb.generate({
		...graphConfig,
		axis: {
			x: { type: 'category', categories: data.labels,
				tick: { rotate: -45, multiline: false, centered: true, culling: {max: 15} } },
			y: { label: { text: `Inventory (${unit})`, position: 'outer-middle' } },
			y2: { show: true, label: { text: `Consumption/Scrap/Orders (${unit})`, position: 'outer-middle' } }
		},
		data: {
			...graphConfig.data,
			columns,
			axes: axes
		},
		size: { height: 660 }

	});
};
